import { Box, Container, Heading, HStack, Icon, Input, SimpleGrid, Textarea, Button, VStack } from "@chakra-ui/react";
import { motion, useAnimation } from "framer-motion";
import { InView } from "react-intersection-observer";
import { FiArrowRight } from "react-icons/fi";
import ReactGA from "react-ga4";

export const ContactForm = () => {
  const MotionBox = motion(Box);
  const controlsY = useAnimation();
  const controlsX = useAnimation();

  const handleInViewChange = (inView: any) => {
    controlsY.start({ y: inView ? 0 : 200, opacity: inView ? 1 : 0 });
    controlsX.start({ x: inView ? 0 : -200, opacity: inView ? 1 : 0 });
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const data = Object.fromEntries(formData.entries());

    ReactGA.event({
      category: "Contact Form",
      action: "Submit Form",
      label: "Contact Form Submission",
    });

    fetch("https://storecontactmessage-aug4cyg3pq-uc.a.run.app", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.ok) {
          alert("Message sent successfully!");
        } else {
          alert("Failed to send message. Please try again later.");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        alert("An error occurred while sending the message. Please try again later.");
      });
  };

  return (
    <Box id="contact" bg="primary" w="100vw" py="10%" pos="relative" overflow="hidden" height={{ base: "fit-content", lg: "auto" }}>
      <Container p={{ base: 16, lg: 24 }} maxW="full">
        <Box my={10}>
          <HStack spacing={10}>
            <Heading pb={2} as="h1" fontWeight={700} size={{ base: "2xl", md: "4xl" }} color="secondary">
              SUBSCRIBE
            </Heading>
            <Heading
              pb={2}
              as="h1"
              fontWeight={700}
              size={{ base: "2xl", md: "4xl" }}
              sx={{
                WebkitTextStroke: "1px",
                WebkitTextFillColor: "transparent",
                WebkitTextStrokeColor: "#F29420",
              }}
            >
              NOW
            </Heading>
            <Icon as={FiArrowRight} color="secondary" fontSize="4xl" />
          </HStack>
        </Box>
        <InView as="div" triggerOnce={false} threshold={0.4} onChange={handleInViewChange}>
          <form onSubmit={handleSubmit}>
            <SimpleGrid mt={5} columns={{ base: 1, md: 2 }} spacing={8} alignItems="stretch">
              {/* Left Side */}
              <MotionBox animate={controlsY} initial={{ y: 200, opacity: 0 }} transition={{ duration: 1 }}>
                <VStack spacing={4} align="stretch">
                  <Input name="name" borderColor="secondary" bg="transparent" color="white" _placeholder={{ color: "white" }} placeholder="Your Name" />
                  <Textarea minH={24} name="message" borderColor="secondary" bg="transparent" color="white" _placeholder={{ color: "white" }} placeholder="Your Message" resize="none" />
                </VStack>
              </MotionBox>
              {/* Right Side */}
              <MotionBox animate={controlsX} initial={{ x: -200, opacity: 0 }} transition={{ duration: 1 }}>
                <VStack spacing={4} align="stretch" height="100%">
                  <Input name="phone" placeholder="Phone Number" borderColor="secondary" color="white" _placeholder={{ color: "white" }} bg="transparent" />
                  <Input name="email" placeholder="Email Address" borderColor="secondary" color="white" _placeholder={{ color: "white" }} bg="transparent" />
                  <Button type="submit" variant="secondary" textAlign="left">
                    Get Started {"->"}
                  </Button>
                </VStack>
              </MotionBox>
            </SimpleGrid>
          </form>
        </InView>
      </Container>
    </Box>
  );
};
