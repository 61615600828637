import { Accordion, Box, Center, Container, Grid, GridItem, Heading, Image, SimpleGrid, Stack, Text } from "@chakra-ui/react";
import { motion, useAnimation } from "framer-motion";
import { InView } from "react-intersection-observer";
import { IoRocket } from "react-icons/io5";
const Image1 = require("../assets/who-are-we-1.png");
const Image2 = require("../assets/who-are-we-2.jpeg");

export const WhoAreWe = () => {
  const MotionBox = motion(Box);
  const MotionCenter = motion(Center);
  const MotionImage = motion(Image);
  const controlsY = useAnimation();
  const controlsX = useAnimation();

  const handleInViewChange = (inView: boolean) => {
    controlsY.start({ y: inView ? 0 : 200, opacity: inView ? 1 : 0 });
    controlsX.start({ x: inView ? 0 : -200, opacity: inView ? 1 : 0 });
  };

  return (
    <Box id="whoarewe" bg="primary" w="100vw" py="10%">
      <Container p={{ base: 16, lg: 24 }} maxW="full">
        <InView as="div" triggerOnce={false} threshold={0.4} onChange={handleInViewChange}>
          <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={{ base: "15%", lg: "15%", xl: "10%" }}>
            {/* Image Grid */}
            <Center gap={3}>
              <Grid templateColumns="50% 50%" h="500px" gap={4} w="100%">
                <GridItem>
                  <MotionImage src={Image1} objectFit="cover" alt="Who Are We Image 1" borderRadius="lg" height="100%" animate={controlsX} initial={{ x: -200, opacity: 0 }} transition={{ duration: 1 }} />
                </GridItem>
                <GridItem as={Grid} templateRows="0.75fr 0.25fr" rowGap={4}>
                  <MotionImage w="100%" h="100%" objectFit="cover" src={Image2} alt="Who Are We Image 2" borderRadius="lg" animate={controlsY} initial={{ y: 200, opacity: 0 }} transition={{ duration: 1 }} />
                  <MotionCenter display="flex" flexDir="row" bg="primaryAccent" color="secondary" borderRadius="lg" boxShadow="lg" animate={controlsY} initial={{ y: 200, opacity: 0 }} transition={{ duration: 1 }}>
                    <Center flex={1}>
                      <IoRocket size="40" />
                    </Center>
                    <Center flex={3} flexDir="column" alignItems="flex-start">
                      <Heading fontWeight={400} size="xl" color="white">
                        40 +{" "}
                      </Heading>
                      <Heading fontWeight={600} size="md" color="white">
                        years experience
                      </Heading>
                    </Center>
                  </MotionCenter>
                </GridItem>
              </Grid>
            </Center>

            {/* Text Content */}

            <Center h="100%" flexDir="column" alignItems="flex-start">
              <MotionBox animate={controlsY} initial={{ y: 200, opacity: 0 }} transition={{ duration: 1 }}>
                <Text pb={2} fontWeight={700} fontSize={{ base: "lg", md: "xl" }} color="white">
                  who are we?
                </Text>
                <Heading pb={2} as="h1" fontWeight={700} size={{ base: "md", md: "lg" }} color="white">
                  Turning creativity into
                </Heading>
                <Heading pb={4} as="h2" fontWeight={700} size={{ base: "md", md: "lg" }} color="secondary">
                  Conversion
                </Heading>
                <Text lineHeight="2" fontSize={{ base: "lg", md: "xl" }} color="whitesmoke" w="100%">
                  CodeHardy is led by a team with over 40 years of combined experience in the tech industry. We take a hands-on, collaborative approach, working closely with clients to create innovative, tailored IT solutions that meet their unique needs. From web and app development to custom IT solutions, our expertise covers every corner of the tech world. Whether you need a modern website, an e-commerce platform, SEO, or even kiosks, we’ve got it covered!
                </Text>
              </MotionBox>
            </Center>
          </SimpleGrid>
        </InView>
      </Container>
    </Box>
  );
};
